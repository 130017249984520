import Swiper from "swiper";
import { Autoplay } from 'swiper/modules';
import { OverlayScrollbars } from 'overlayscrollbars';
import isMotionOk from "../../../scripts/utils/isMotionOk";

const logoMarqueeSections = document.querySelectorAll('.section--logo-marquee');

if ( logoMarqueeSections.length ) {
	window.addEventListener('load', () => {
		logoMarqueeSections.forEach( $section => {
			const $swiperElm = $section.querySelector('.swiper');

			if ( !$swiperElm ) return;

			if ( isMotionOk() ) {
				let swiper = new Swiper($swiperElm, {
					modules: [Autoplay],
					allowTouchMove: false,
					speed: 3000,
					loop: true,
					spaceBetween: 64,
					grabCursor: true,
					autoplay: {
						disableOnInteraction: true,
						delay: 1
					},
					slidesPerView: 3,
					breakpoints: {
						768: {
							slidesPerView: 4
						},
						992: {
							slidesPerView: 5,
							speed: 4000,
						},
						1200: {
							slidesPerView: 5,
							speed: 4000,
						}
					}
				});
			} else {
				OverlayScrollbars($swiperElm, {
					overflow: {
						y: 'hidden',
					},
					scrollbars: {
						theme: 'os-theme-dark',
						visibility: 'auto',
						autoHide: 'never',
						dragScroll: true,
					},
				 });
			}
		} );
	});
}


