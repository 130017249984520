import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Debounce from '../../../scripts/utils/debounce';
import isMotionOk from '../../../scripts/utils/isMotionOk';

Chart.register(ChartDataLabels);
gsap.registerPlugin(ScrollTrigger);

const $chartSection = document.querySelector('.section--bechtel-org-chart');

const convertToK = (value) => {
	return Math.abs(value) > 999 ? Math.sign(value)*((Math.abs(value)/1000).toFixed(1)) + 'k' : Math.sign(value)*Math.abs(value)
};

const addCommasToNumber = (value) => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const htmlLegendPlugin = {
	id: 'htmlLegend',
	afterUpdate(chart, args, options) {
	  const ul = document.querySelector('.chart-legend__inner');

	  // Remove old legend items
	  while (ul.firstChild) {
		ul.firstChild.remove();
	  }

	  // Reuse the built-in legendItems generator
	  const items = chart.options.plugins.legend.labels.generateLabels(chart);

	  // reverse items order. We want the green to be first inside the legend but the chart needs the line to be first so that it overlaps the bar chart.
	  items.reverse();

	  items.forEach(item => {
		const li = document.createElement('div');
		li.classList.add('chart-legend__item');

		// Color box
		const boxSpan = document.createElement('div');
		boxSpan.classList.add('indicator');
		boxSpan.style.background = item.fillStyle;
      	boxSpan.style.borderColor = item.strokeStyle;

		// Text
		const text = document.createTextNode(item.text);

		li.appendChild(boxSpan);
		li.appendChild(text);
		ul.appendChild(li);
	  });
	}
  };

if ( $chartSection ) {
	const $bechtelChart = $chartSection && $chartSection.querySelector('#bechtel-org-chart');
	const toggleButtons = $chartSection && $chartSection.querySelectorAll('.button');
	const $btnToggleEmissions = document.querySelector('.btn-toggle-emissions');
	const $btnToggleWaterConsumed = document.querySelector('.btn-toggle-water-consumed');
	let chart;
	let delayed;

	const updateButton = (button) => {
		toggleButtons.forEach(btn => btn.classList.remove('is-active'));
		button.classList.add('is-active');
	}

	const updateChart = (newData, scales) => {
		chart.data = JSON.parse(JSON.stringify(newData));
		if (scales) {
			chart.options.scales = scales;
		}
		chart.update();
	}

	const labels = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];

	const initialData = {
		labels: labels,
		datasets: [
			{
				label: 'Sum of Indirect CO2e Emissions (t)',
				data: [0, 0, 0, 0, 0, 0, 0],
				borderColor: 'transparent',
				backgroundColor: '#8AB08A',
				hoverBackgroundColor: '#8AB08A',
				yAxisID: 'y',
				datasetId: 'emissions',
				order: 1,
			},
			{
				label: 'Sum of Revenue Intensity (CO2e/Billion USD)',
				data: [0, 0, 0, 0, 0, 0, 0],
				borderColor: '#fff',
				backgroundColor: '#fff',
				type: 'line',
				order: 0,
				yAxisID: 'y1',
				datalabels: {
					anchor: 'end',
					align: 'start',
				},
			}
		]
	};

	const emissionsData = {
		labels: labels,
		datasets: [
			{
				label: 'Sum of Indirect CO2e Emissions (t)',
				data: [15525, 14708, 12421, 11892, 8272, 7716, 5997],
				borderColor: 'transparent',
				backgroundColor: '#8AB08A',
				hoverBackgroundColor: '#8AB08A',
				yAxisID: 'y',
				datasetId: 'emissions',
				order: 1,
				datalabels: {
					enabled: false,
				},
			},
			{
				label: 'Sum of Revenue Intensity (CO2e/Billion USD)',
				data: [472, 588, 487, 548, 470, 441, 357],
				borderColor: '#fff',
				backgroundColor: '#fff',
				type: 'line',
				order: 0,
				yAxisID: 'y1',
				datalabels: {
					// anchor: 'end',
					align: 'top',
				},
			}
		]
	};

	const consumedData = {
		labels: labels,
		datasets: [
			{
				label: 'Water Consumed (gal)',
				data: [56120471, 49646022, 29711544, 27274245, 14772883, 7982988, 13203188],
				borderColor: 'transparent',
				backgroundColor: '#245796',
				hoverBackgroundColor: '#245796',
				yAxisID: 'y',
				datasetId: 'consumed',
				datalabels: {
					anchor: 'end',
					align: 'start',
				},
			},
		]
	};

	const defaultScales = {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				color: '#fff',
			}
		},
		y: {
			beginAtZero: true,
			title: {
				display: true,
				text: 'Sum of Indirect CO2e Emissions (t)',
				color: '#fff',
				font: {
					size: 20,
					weight: '500',
				}
			},
			ticks: {
				stepSize: 5000,
				padding: 10,
				color: '#fff',
				callback: function(value, index, ticks) {
					return convertToK(value);
				}
			},
			min: 0,
			max: 20000,
			border: {
				display: false,
			},
			grid: {
				color: '#57727F',
			}
		},
		y1: {
			type: 'linear',
			display: true,
			position: 'right',
			ticks: {
				stepSize: 100,
				padding: 10,
				color: '#fff',
			},
			min: 0,
			max: 700,

			// grid line settings
			grid: {
				drawOnChartArea: false, // only want the grid lines for one axis to show up
			},
		},
	};

	const consumedScales = {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				color: '#fff',
			}
		},
		y: {
			beginAtZero: true,
			title: {
				display: true,
				text: 'Water Consumed (gal)',
				color: '#fff',
				font: {
					size: 20,
					weight: '500',
				}
			},
			grace: '5%',
			ticks: {
				// stepSize: 10000000,
				padding: 10,
				color: '#fff',
			},
			border: {
				display: false,
			},
			grid: {
				color: '#57727F',
			}
		},
	};

	const chartConfig = {
		type: 'bar',
		data: initialData,
		options: {
			scales: defaultScales,
			animation: {
				onComplete: () => {
					delayed = true;
				},
				delay: (context) => {
					let delay = 0;
					if (context.type === 'data' && context.mode === 'default' && !delayed) {
						delay = context.dataIndex * 300 + context.datasetIndex * 100;
					}
					return delay;
				},
				duration: isMotionOk() ? 1000 : 0,
			},
			responsive: true,
			plugins: {
				legend: {
					display: false,
				},
				htmlLegend: {},
				tooltip: {
					enabled: false,
				},
				datalabels: {
					padding: 6,
					color: '#fff',
					font: {
						weight: '700',
						size: 16,
					},
					formatter: function(value, context) {
						const isMobile = window.innerWidth <= 768;
						// if (context.dataset.datasetId === 'emissions' ) {
						// 	return isMobile ? '' : addCommasToNumber(value) + 't';
						// }
						// if (context.dataset.datasetId === "consumed" ) {
						// 	return value + ' gal';
						// }
						return value;
					}
				},
			},
			onResize: function(chart, size) {
				if (size.width <= 768) {
					chart.options.scales.y.title = false;
					chart.options.plugins.datalabels.font.size = 10;
				} else {
					chart.options.scales.y.title = true;
				}
			},
		},
		plugins: [htmlLegendPlugin],
	};

	const chartInit = () => {
		if ( isMotionOk() ) {
			ScrollTrigger.create({
				trigger: $chartSection,
				start: "top top+=30%",
				end: "bottom center",
				onEnter: () => {
					updateChart(emissionsData, defaultScales);
				},
				once: true,
				// markers: true
			});
		} else {
			updateChart(emissionsData, defaultScales);
		}

		const isMobile = window.innerWidth <= 768;

		defaultScales.y.title.display = isMobile ? false : true;
		consumedScales.y.title.display = isMobile ? false : true;
	};

	$btnToggleEmissions.addEventListener('click', () => {
		updateChart(emissionsData, defaultScales);
		updateButton($btnToggleEmissions);
	});

	$btnToggleWaterConsumed.addEventListener('click', () => {
		updateChart(consumedData, consumedScales);
		updateButton($btnToggleWaterConsumed);
	});

	document.addEventListener('DOMContentLoaded', () => {
		chart = new Chart($bechtelChart, chartConfig);
	});

	window.addEventListener('load', () => {
		chartInit();
	});

	window.addEventListener('resize', Debounce( () => {
		const isMobile = window.innerWidth <= 768;

		chart.options.scales.y.title = isMobile ? false : true;

		chart.update();
	}, 100) );
}
