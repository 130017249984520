import isMotionOk from "./isMotionOk";

const parallaxEffect = ($element, speed) => {
	if (!isMotionOk()) return;
	let requestId;

	const updateParallax = () => {
		const scrollPosition = window.scrollY;
		const translateY = scrollPosition * speed;


		$element.style.transform = `translate3d(0, ${translateY}px, 0)`;
		$element.style.opacity = 1 - scrollPosition / $element.offsetHeight;

		requestId = requestAnimationFrame(updateParallax);
	};

	const startParallax = () => {
		if (!requestId) {
			requestId = requestAnimationFrame(updateParallax);
		}
	};

	const stopParallax = () => {
		if (requestId) {
			cancelAnimationFrame(requestId);
			requestId = undefined;
		}
	};

	window.addEventListener('scroll', startParallax);
	window.addEventListener('resize', stopParallax);
};

export default parallaxEffect;
