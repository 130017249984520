module.exports = [
    {
        id: 1,
        category: 1,
        location: "Tennessee, U.S.",
        title: 'Uranium Processing Facility',
        teaser: "Bechtel is constructing a new facility at the Y-12 National Security Complex to ensure the long-term viability, safety, and security of enriched uranium capabilities in the U.S. The modernization will fortify the country’s nuclear defense, reduce global threats, improve worker safety, and lower operating and maintenance costs."
    },
    {
        id: 3,
        category: 1,
        location: "Florida, U.S.",
        title: 'Mobile Launcher 2',
        teaser: "Bechtel is proud to partner with NASA in their pursuit to safely send astronauts to the Moon and Mars in the Artemis IV mission and beyond. Bechtel is designing, building, testing, and commissioning the Mobile Launcher 2 – a new ground system that will be used to support the Space Launch System (SLS) launch operations. In 2023, the Bechtel team started construction of the mobile launcher on schedule at Kennedy Space Center and is continuing to assemble the 390-foot-tall mobile launcher."
    },
    {
        id: 5,
        category: 1,
        location: "Colorado and Kentucky, U.S.",
        title: 'Blue Grass & Pueblo Chemical Agent-Destruction Plants',
        teaser: "A Bechtel-led team safely destroyed the United States’ declared chemical weapons stockpile at these Bechtel-built-and-operated facilities, which held over 3,000 tons of chemical agents in artillery projectiles, mortar rounds, and M55 rockets. Both facilities began final campaigns in 2022 to support national commitments under the 1997 Chemical Weapons Convention to meet the September 2023 deadline."
    },
    {
        id: 6,
        category: 1,
        location: "United States",
        title: 'Sentinel (Ground-Based Strategic Deterrent, GBSD)',
        teaser: "Bechtel is working closely with its customer, Northrop Grumman, to help design and construct the launch infrastructure component of Sentinel, the U.S. Air Force’s next-generation ground-based strategic deterrent system. This system will replace the existing Minuteman III system as the land-based leg of the U.S. strategic deterrent. Currently in the Engineering & Manufacturing Development (EMD) phase of its contract, Bechtel is focused on reimagining, designing, and delivering constructable solutions for launch infrastructure that will support the flight test program and production integration across three Air Force bases in the western United States."
    },
    {
        id: 7,
        category: 1,
        location: "California, U.S.",
        title: 'Lawrence Livermore U.S. National Laboratory (LLNL)',
        teaser: "Since 2007, Bechtel has managed and operated the Lawrence Livermore National Laboratory (LLNL) for the National Nuclear Security Administration (NNSA), whose work strengthens global security and promotes breakthrough science. The team supports the laboratory’s mission to develop technology in support of national security and defense, maintain the nuclear stockpile, and research emerging energy technologies. In 2023, LLNL scientists achieved a breakthrough in fusion research and started installing components for NNSA’s first exascale supercomputer, El Capitan."
    },
    {
        id: 8,
        category: 1,
        location: "United States",
        title: 'Bechtel Plant Machinery, Inc. (BPMI)',
        teaser: "Bechtel Plant Machinery, Inc. (BPMI) is a project engineering organization and a Prime Contractor for the Naval Nuclear Propulsion Program (NNPP). BPMI provides design, manufacturing, and supply chain management of nuclear power plant components installed in submarines and aircraft carriers for the U.S. Navy, enabling them to fulfill their critical mission. For nearly 70 years, BPMI has been at the forefront of technological and operational innovation for the Navy and the NNPP."
    },
    {
        id: 13,
        category: 2,
        location: "Africa",
        title: 'CWP Global Green Hydrogen Facilities',
        teaser: "CWP Global partnered with Bechtel Energy Technologies & Solutions to develop conceptual and early planning solutions for large-scale green hydrogen and ammonia facilities in Africa."
    },
    {
        id: 14,
        category: 2,
        location: "U.S.",
        title: 'H Cycle Low-Carbon Hydrogen Program',
        teaser: "H Cycle selected Bechtel to support the development of a series of hydrogen plants across the U.S. to meet increased demand for low-carbon hydrogen using a technology that extracts hydrogen from municipal waste using heat and electricity."
    },
    {
        id: 15,
        category: 2,
        location: "Oaxaca, Mexico",
        title: 'Salina Cruz Refinery',
        teaser: "Pemex chose Bechtel Energy Technologies & Solutions’ ThruPlus® Delayed Coking technology to increase efficiency at its Salina Cruz refinery and expand its capacity by 75,000 barrels per day, supporting greater energy security in Mexico. The ThruPlus® technology now has more than 70 licensed units globally. In 2023, BETS completed the 90% model review with Pemex and its detail engineering contractor."
    },
    {
        id: 16,
        category: 2,
        location: "Karratha, Australia",
        title: 'Pluto Train 2',
        teaser: "Bechtel began construction on the Woodside Energy-operated Pluto LNG facility in 2022, after being selected to handle the full engineering, procurement, construction, commissioning, and start-up scopes of work to increase the facility’s capacity by another 5 million tonnes per annum. The facility will contribute to long-term economic growth and local employment opportunities for Western Australia. All 51 modules are expected to be shipped to Australia in 2024, marking a significant change to the skyline at the jobsite as works come out of the ground. The Scarborough Energy Project is targeting first cargo in 2026."
    },
    {
        id: 17,
        category: 2,
        location: "Texas, U.S.",
        title: 'Port Arthur LNG Phase 1',
        teaser: "Since the Port Arthur Phase 1 project received Final Notice to Proceed in March 2023, the team has achieved a phenomenal amount of infrastructure and energy-related work including the cut-and-fill of more than 4 million cubic yards of material, the installation of over 10,000 piles and 18,000 cubic yards of concrete, and initial installation of the structural steel and pipe for train 1. The project's success is deeply connected to the local community, with a 2023 reported local hire rate of 51.16% and strong partnerships with local vendors."
    },
    {
        id: 18,
        category: 2,
        location: "Louisiana, U.S.",
        title: 'Driftwood LNG',
        teaser: "Tellurian gave Bechtel a second Limited Notice to Proceed for the Driftwood LNG export facility. Once complete, the facility’s export capacity will be nearly 28 million metric tons per year, helping to meet global LNG demand. The first phase will consist of two plants, each with up to four liquefaction trains, creating roughly 400 direct jobs and 6,500 construction jobs."
    },
    {
        id: 19,
        category: 2,
        location: "Texas, U.S.",
        title: 'Rio Grande LNG',
        teaser: "Bechtel and NextDecade officially broke ground on the Rio Grande LNG Phase 1 project, Texas’s largest privately funded LNG project that will consist of three LNG trains, adding 17.6 million metric tons per year to global LNG capacity and two 180,000 cubic meter full containment tanks. At full scale, this project will deliver enough energy to heat and cool the equivalent of nearly 34 million U.S. households annually. To date, an estimated 60 tons of steel have been installed, and upon completion, Train 1 will encompass over 16,000 tonnes of structural steel."
    },
    {
        id: 20,
        category: 2,
        location: "Texas, U.S.",
        title: 'HIF Global Matagorda eFuels Facility',
        teaser: "HIF Matagorda awarded Bechtel the front-end engineering design for its eFuels facility, which will produce gasoline-methanol that can be used in vehicles without any modification to existing engines or infrastructure. The fuel will be produced by utilizing CO2 captured from the atmosphere and green hydrogen from renewable power. By 2027, HIF USA expects the potential to decarbonize over 400,000 vehicles."
    },
    {
        id: 21,
        category: 2,
        location: "Texas, U.S.",
        title: 'Cutlass Solar 1 & 2',
        teaser: "Bechtel completed Cutlass Solar project 1 and 2, providing clean energy for thousands of homes. Cutlass Solar 1 added 140 megawatts in the Houston area, enough to power 20,000 homes with zero-carbon electricity and eliminate 300,000 metric tons of CO2 emissions. Cutlass Solar  2 will add another 218 megawatts of solar power through the installation of approximately 500,000 solar panels."
    },
	{
        id: 21,
        category: 2,
        location: "Texas, U.S.",
        title: 'Posey and Oriana Solar',
        teaser: "Bechtel has received Final Notice to Proceed to begin mobilizing our team and commencing construction of both Posey Solar and Oriana Solar facilities in the U.S. The milestones demonstrate positive momentum in our solar power work and will support America’s drive for more low-carbon energy production."
    },
    {
        id: 22,
        category: 2,
        location: "Idku, Egypt",
        title: 'Egyptian LNG Export Terminal Zero-Flaring Study',
        teaser: "A Bechtel-led Coalition was selected to assess options to reduce methane emissions within the zero-flaring system at the Egyptian LNG (ELNG) export terminal. The project supports the Methane Global Pledge signed by Egypt in June 2022. The feasibility study has since been completed and presented to ELNG for further approval."
    },
    {
        id: 27,
        category: 2,
        location: "Texas and Louisiana, U.S.",
        title: 'Corpus Christi and Sabine Pass Liquefaction Facilities',
        teaser: "The Stage 3 expansion project at Cheniere’s Corpus Christi Liquefaction export plant in Texas was 51.4 percent construction complete in December 2023, and in 2022 Bechtel completed the sixth production train at the Sabine Pass Liquefaction Facility. Both projects continue to add to U.S. LNG export capacity at a time when the focus on global energy security and energy transition is driving a growing role for natural gas as a lower-carbon alternative to coal."
    },
    {
        id: 30,
        category: 3,
        location: "Sydney, Australia",
        title: 'Sydney Metro West',
        teaser: "Bechtel is delivering the driverless, underground Sydney Metro West, a once-in-a-century infrastructure investment for the Government of New South Wales. This landmark project will transform Sydney for generations to come, doubling rail capacity between Parramatta and the Sydney Central Business District, linking new communities to rail services, and supporting employment growth and housing supply."
    },
    {
        id: 31,
        category: 3,
        location: "Riyadh, Saudi Arabia",
        title: 'Riyadh Metro',
        teaser: "A Bechtel-led consortium is designing and building Lines 1 and 2 of Saudi Arabia’s first underground metro system. Continuing Bechtel’s 80-year legacy of working on major projects in Saudi Arabia, this critical infrastructure project will reduce traffic congestion, improve air quality, provide easier access to economic opportunities across the region, and support Riyadh as its population expands from 6 million to 8 million people by 2030."
    },
    {
        id: 33,
        category: 3,
        location: "Toronto, Canada",
        title: 'Ontario Line',
        teaser: "Bechtel continues to work as the delivery partner for Metrolinx’s rapid transit Ontario Line, which includes 15 new stations and will give Toronto residents a more reliable transit network by alleviating crowding and congestion. The project will make up to 47,000 jobs accessible in 45 minutes or less, take 28,000 cars off the road, and reduce fuel consumption by 7.2 million liters per year."
    },
    {
        id: 34,
        category: 3,
        location: "California, U.S.",
        title: 'Sepulveda Transit Corridor',
        teaser: "Bechtel is making great progress on a high-capacity automated rail line in Los Angeles through one of the most congested corridors in the U.S. We’ve recently completed all PDA Phase 3 deliverables, bringing us another step closer to a transformative transit solution for Angelenos. Our team is responsible for two options under consideration to link the San Fernando Valley to the Westside (and eventually to Los Angeles International Airport), which would reduce the relentless traffic congestion on the 405 Freeway."
    },
    {
        id: 35,
        category: 3,
        location: "Tabuk Province, Saudi Arabia",
        title: 'NEOM TROJENA',
        teaser: "Bechtel is providing project management support for various parts of NEOM, including its year-round mountain destination, TROJENA. The NEOM project will help transform Saudi Arabia by expanding its local economy, boosting tourism, and providing employment opportunities. Trojena, a built-from-scratch alpine resort and residential community, is a centerpiece of NEOM."
    },
    {
        id: 36,
        category: 3,
        location: "U.S.",
        title: 'First Student School Bus Electrification',
        teaser: "Bechtel is designing and building infrastructure to support electric vehicle charging for North America’s largest school bus operator, First Student, which is working to convert 30,000 of its school buses to run on electricity by 2035."
    },
    {
        id: 37,
        category: 3,
        location: "Sydney, Australia",
        title: 'Western Sydney International Airport',
        teaser: "Bechtel is helping deliver this world-leading, full-service facility for international, domestic, and freight flights. Western Sydney International (Nancy-Bird Walton) Airport, which is being built using sustainable design elements to minimize its environmental impacts, will catalyze regional economic development, provide access to high-quality jobs, and create business opportunities for local communities."
    },
    {
        id: 38,
        category: 3,
        location: "London, U.K.",
        title: 'Elizabeth Line',
        teaser: "Bechtel supported the completion of Crossrail’s Elizabeth Line, which includes 10 newly built and 31 newly upgraded stations. The Elizabeth Line increased London’s rail capacity by 10% and brought an additional 1.5 million people within 45 minutes of central London, reducing journey times and transforming accessibility in the city."
    },
    {
        id: 39,
        category: 3,
        location: "Serbia",
        title: 'Morava Corridor Motorway Project',
        teaser: "Bechtel is fostering economic growth throughout Serbia with the construction of the Morava Corridor, which will connect commercial centers within Serbia and expand access to markets in Slovenia, Croatia, North Macedonia, Italy, and Romania. The motorway will also include an expansive 5G telecommunication network and a flood defense system to protect local communities. The first section of the motorway opened in 2023."
    },
    {
        id: 40,
        category: 3,
        location: "New Delhi, India",
        title: 'Noida International Airport',
        teaser: "Bechtel is supporting Tata Projects in its delivery of India’s first net-zero emissions airport. Modern, hyper-efficient, and passenger-centric, Noida International Airport will eventually serve New Delhi and will be India’s largest airport, with an opening capacity of 12 million passengers annually."
    },
    {
        id: 41,
        category: 4,
        location: "New Mexico, U.S.",
        title: 'Waste Isolation Pilot Plant (WIPP)',
        teaser: "A Bechtel company, Salado Isolation Mining Contractors (SIMCO), manages and operates the Waste Isolation Pilot Plant (WIPP) for the U.S. Department of Energy. Located in a salt formation more than 2,100 feet underground, WIPP is the world’s only operating, deep, geologic repository for defense-related transuranic nuclear waste."
    },
    {
        id: 42,
        category: 4,
        location: "Washington, U.S.",
        title: 'Hanford Waste Treatment Plant (WTP)',
        teaser: "This one-of-a-kind complex will immobilize up to 56 million gallons of waste stored in 176 aging underground tanks from World War II and the Cold War. Designed by Bechtel, the plant uses vitrification to heat radioactive waste and glass-forming materials to 2,100 degrees Fahrenheit. Once the molten material cools and solidifies into glass, it can be safely and securely disposed of, protecting the Columbia River and surrounding communities."
    },
	{
        id: 42,
        category: 4,
        location: "Tennessee, U.S.",
        title: 'Y-12 National Security Complex',
        teaser: `A Bechtel-led subsidiary, Consolidated Nuclear Security (CNS), runs the maintenance and operations at the Y-12 National Security Complex in Tennessee. The project supports the Department of Energy and National Nuclear Security Administration in their mission to maintain the safety, security, and effectiveness of the U.S. nuclear weapons stockpile.<br><br>

In 2023, Y-12 opened a new fire station that supports Y-12’s critical mission to upkeep the U.S. nuclear deterrent through advanced handling of uranium, including enrichment, processing, testing, training, and security. CNS also broke ground on the Lithium Processing Facility (LPF), a new 245,000-square-foot facility that will feature updated technology, increase processing capacity, and make the work environment safer for employees. As the only facility with lithium processing and production capabilities, Y-12 supplies lithium materials to support U.S. defense missions as the NNSA’s Lithium Center of Excellence.`
    },
    {
        id: 43,
        category: 4,
        location: "Hamburgo, Chile",
        title: 'Hamburgo Tailings Removal Project',
        teaser: "Bechtel completed the pre-feasibility study for the removal of the Hamburgo tailings dam, which has not been used since 2002, from the Escondida mine pit. This project is crucial for Escondida, allowing the mine to expand and process 300 metric tons in the dam."
    },
    {
        id: 44,
        category: 4,
        location: "Sellafield, U.K.",
        title: 'Pile Fuel Cladding Silo (PFCS)',
        teaser: "The Sellafield PFCS, Europe’s largest nuclear site and widely considered one of the four most hazardous buildings in Western Europe, was commissioned in 1952 to safely store radioactive material used in some of the U.K.’s earliest nuclear reactors. Bechtel Cavendish Nuclear Solutions is delivering a system that will allow Sellafield to retrieve, package, and dispose of this waste safely and permanently."
    },
    {
        id: 45,
        category: 4,
        location: "Northern Territory, Australia",
        title: 'Ranger Mine Rehabilitation Project',
        teaser: "Bechtel is supporting Rio Tinto as it delivers the Ranger Rehabilitation Project on behalf of Energy Resources of Australia. An environmentally sensitive site located adjacent to a World Heritage-listed national park, the project focuses on the environmental rehabilitation and closure of the mine aligned with the mine closure plan."
    },
	{
        id: 45,
        category: 4,
        location: "Peru",
        title: 'Yanacocha WTP',
        teaser: "Located in Cajamarca, Peru, the Yanacocha Water Treatment Plant project includes two of the largest acid water treatment plants in the region. Bechtel is supporting our customer Newmont to develop critical facilities to treat and recover water at South America’s largest gold mine, while also helping to protect the environment and foster economic growth and employment for local communities."
    },
    {
        id: 46,
        category: 5,
        location: "Ohio, U.S.",
        title: 'Intel Semiconductor Plant Facility',
        teaser: "Bechtel is designing and building Intel’s new advanced semiconductor manufacturing facility, which will revitalize chipmaking in the U.S., restore dependability and resilience to the global semiconductor supply chain, and strengthen economic development in the region. Partnering with North America’s Building Trades Unions and suppliers, Bechtel will also create new construction jobs and implement training programs with local education organizations."
    },
    {
        id: 47,
        category: 5,
        location: "U.S.",
        title: 'Mitra Chem Cathode Manufacturing Plant',
        teaser: "Bechtel will design Mitra Chem’s commercial cathode manufacturing plant, which will produce lithium iron phosphate cathodes on a commercial scale. With Bechtel’s support, Mitra Chem will be able to rapidly scale its operations and deliver on its mission to create mass-market electrification for electric vehicles, energy storage solutions, and beyond."
    },
    {
        id: 48,
        category: 5,
        location: "Manama, Bahrain",
        title: 'Alba New Replacement Line',
        teaser: "Bechtel completed the feasibility study for Alba’s New Replacement Line (formerly known as Line 7), exploring the sustainable expansion of one of the world’s largest aluminum smelters. The New Replacement Line is estimated to have a production capacity of approximately 381,000 metric tons per year."
    },
    {
        id: 49,
        category: 5,
        location: "Pennsylvania, U.S.",
        title: 'Shell Polymers Monaca',
        teaser: "Bechtel completed construction of the Shell Polymers Monaca petrochemicals complex, which will produce 1.6 million tonnes of polyethylene per year for use in manufacturing a wide range of everyday products. Shell’s Monaca plant is a world-scale facility nearly a decade in the making, offering communities in the region access to job opportunities and business development."
    },
    {
        id: 50,
        category: 5,
        location: "U.S.",
        title: 'Sicona Battery Technologies Anode Manufacturing Plant',
        teaser: "Sicona Battery Technologies awarded Bechtel an engineering study for its first U.S. manufacturing plant, which will produce the cutting-edge battery anode components needed for electric vehicles. Bechtel will help Sicona optimize manufacturing area designs, design requirements, utilities, equipment, and materials."
    },
    {
        id: 51,
        category: 6,
        location: "Nevada, U.S.",
        title: 'Thacker Pass Lithium Mine',
        teaser: "Bechtel helped Lithium Americas Corp. advance the early works and engineering design on its nearly $3B lithium mine and processing facility at Thacker Pass in northern Nevada. Pending final approval of a loan from the U.S. Department of Energy, the project aims to responsibly access the critical resources needed to strengthen the country’s electric vehicle battery supply chain and secure the nation’s energy future."
    },
    {
        id: 52,
        category: 6,
        location: "Tarapacá, Chile",
        title: 'Collahuasi Mine',
        teaser: "As the project management consultant for C20+, Bechtel is supporting expansion and efficiency improvements at one of the world’s largest copper operations. To help meet global demand and avoid tapping precious freshwater reserves, a desalination plant will transport seawater to the mine’s facilities 4,400 meters above sea level."
    },
    {
        id: 53,
        category: 6,
        location: "Victoria, Australia",
        title: 'Latrobe Magnesium Stage 3',
        teaser: "Latrobe Magnesium awarded Bechtel the pre-feasibility study for the development of its Stage 3 International Magnesium Mega Plant with an annual capacity of 100,000 tons. The lightest of all structural materials, 100% recyclable, and with the best strength-to-weight ratio of all common structural metals, magnesium is increasingly used in car parts, the aviation industry, laptop computers, mobile phones, and power tools."
    },
    {
        id: 55,
        category: 6,
        location: "Central Arabian Gold Region, Saudi Arabia",
        title: 'Mansourah-Massarah Gold Project',
        teaser: "Bechtel recently completed the Mansourah-Massarah Gold Project (MMGP), a critical component of Saudi Arabia’s strategy to create a more diverse and sustainable economy."
    },
    {
        id: 56,
        category: 6,
        location: "Tarapacá Region, Chile",
        title: 'Quebrada Blanca Phase 2',
        teaser: "Bechtel helped extend the life and increase the output of Teck’s Quebrada Blanca copper mine, which taps one of the world’s largest undeveloped copper resources and helps close the gap between global supply and rising demand for copper. The project was inaugurated in October 2023. QB2 is expected to generate 316,000 tonnes of copper-equivalent production per year for the first five full years of mine life."
    },
    {
        id: 57,
        category: 6,
        location: "Coquimbo Region, Chile",
        title: 'Los Pelambres',
        teaser: "Bechtel supported Antofagasta Minerals (AMSA) to sustain the Los Pelambres mine’s production level while reducing environmental impact via the INCO project. The project included the installation of a new mill and flotation line, a reverse osmosis desalination plant—filtering 106 gallons (400 liters) of water per second—and a 61-kilometer water conveyance system to serve the industrial area."
    },
    {
        id: 58,
        category: 6,
        location: "Karratha, Australia",
        title: 'Parker Point Port',
        teaser: "The Parker Point Stockyard Sustaining (PPSS) Project will replace critical machinery to sustain the capacity and efficiency of this iron ore port for our customer Rio Tinto in Western Australia."
    },
    {
        id: 59,
        category: 6,
        location: "Canada",
        title: 'Jansen Stage 1',
        teaser: "Bechtel has been supporting Bantrel with the execution of the Jansen Stage 1 Potash project in Saskatchewan, Canada. This is a Bantrel JV with Hatch working in an Integrated Project Team (IPT) with BHP. Engineering is complete and the project has entered the module fabrication and mechanical/piping installation phase. First product milestone is targeted for Q2 2026."
    },
    {
        id: 60,
        category: 7,
        location: "Georgia, U.S.",
        title: 'Plant Vogtle Units 3 & 4',
        teaser: `Tasked with delivering the next generation of nuclear power in the U.S., Bechtel was hired by Georgia Power and Southern Nuclear in 2017 to complete the construction of two new AP1000 nuclear reactors, Units 3 and 4, at Plant Vogtle in Waynesboro, Georgia. These are the first new nuclear reactors to be built in the United States in more than 30 years. <br><br>

		Bechtel has completed construction on both units, and Units 3 & 4 have entered into commercial operations, which means all the testing has been completed and the units are available for reliable dispatch.
With this milestone, Plant Vogtle is the largest generator of clean energy in the U.S. with each of the new units capable of generating enough electricity to power an estimated 500,000 homes and businesses.
`
    },
    {
        id: 61,
        category: 7,
        location: "Wyoming, U.S.",
        title: 'Natrium',
        teaser: `Bechtel is TerraPower's engineering, procurement, and construction partner on this first-of-a-kind project in Kemmerer, Wyoming. The project is part of the U.S. Department of Energy’s Advanced Reactor Demonstration Program.<br><br>

		As the nexus of TerraPower and GE Hitachi technology, the Natrium Demonstration Plant’s design represents a leap forward in clean, sustainable power generation. The project will bring a commercial, advanced nuclear reactor online that will deliver carbon-free, reliable power to the electrical grid and provide jobs in Wyoming for decades to come. Bechtel adds current and unmatched experience managing and executing large nuclear projects to the team.`
    },
    {
        id: 62,
        category: 7,
        location: "Poland",
        title: 'Polish Nuclear Power Program',
        teaser: "Bechtel has been selected to partner on the design and construction of Poland’s first nuclear power plant at the Lubiatowo-Kopalino site in Pomerania. In partnership with Westinghouse Electric Company and Polish investor Polskie Elektrownie Jądrowe, the consortium will deliver three AP1000® reactors for its inaugural nuclear energy program."
    },
];
