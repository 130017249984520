import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { OverlayScrollbars } from 'overlayscrollbars';
import isMotionOk from '../../../scripts/utils/isMotionOk';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

class AnchorMenuComponent extends HTMLElement {
	constructor() {
		super();

		this.$subMenuContainer = null;
		this.$firstSection = null;
		this.$siteFooter = null;
		this.$anchorMenu = null;
		this.$scrollContainer = null;
		this.$scrollContainerScroller = null;
		this.menuItems = [];
		this.anchoredSections = [];
		this.headerHeight = 0;
		this.stickyMenuHeight = 0;
	}

  	connectedCallback() {
		this.$subMenuContainer = this;
		this.$firstSection = document.querySelector('.section--hero');
		this.$siteFooter = document.querySelector('.site-footer');
		this.$scrollContainer = document.querySelector('.scroll-container');
		this.$anchorMenu = this.querySelector('.anchor-menu');
		this.menuItems = this.$anchorMenu && Array.from(this.$anchorMenu.querySelectorAll('a'));

		this.anchoredSections = this.menuItems && this.menuItems.map((menuItem) => {
			const sectionId = menuItem.getAttribute('href').split('#')[1];
			return document.getElementById(sectionId);
		});

		this.headerHeight = parseInt(
		getComputedStyle(document.documentElement).getPropertyValue('--header-height')
		);
			this.stickyMenuHeight = this.$subMenuContainer
			? this.$subMenuContainer.offsetHeight
			: 0;

		this.init();
	}

	init() {
		if (!this.$subMenuContainer) {
			return;
		}

		const heightHandler = () => {
			this.setSubMenuContainerHeight(this);
		};

		window.addEventListener('load', () => {
			heightHandler();
			this.anchorMenuHighlight();
			this.anchorLinkGoTo();
			this.scrollBar();
		});

		window.addEventListener('resize', heightHandler);
		window.addEventListener('scroll', () => this.scrollProgress());
	}


  	clamp(value, min, max) {
		return Math.min(Math.max(value, min), max);
	}

	getValueAfterHash(url) {
		// Use a regular expression to match the text after the '#' symbol
		const match = url.match(/#(.+)/);

		// Check if there's a match
		if (match && match.length > 1) {
			// Return the value after the '#' symbol
			return match[1];
		} else {
			// Return null if there's no match
			return null;
		}
	};

	getSectionTop(section) {
		return section.offsetTop;
	};

	setSubMenuContainerHeight( $subMenuContainer ) {
		const height = $subMenuContainer.offsetHeight;
		document.documentElement.style.setProperty('--sub-menu-container-height', `${height}px`);
	}

	scrollProgress() {
		const winScroll = (document.body.scrollTop || document.documentElement.scrollTop) + this.headerHeight;
		const firstSectionHeight = this.$firstSection.offsetHeight;
		const siteFooterTop = this.$siteFooter.offsetTop;

		const start = firstSectionHeight;
		const end = siteFooterTop - this.stickyMenuHeight;

		let scrolled = (((winScroll - start) / (end - start)) * 100);

		scrolled = this.clamp(scrolled, 0, 100);

		document.documentElement.style.setProperty('--scroll-progress', scrolled + '%');
	}

	anchorMenuHighlight() {
		const exploreMoreSection = document.querySelector('.section--explore-more');

		if (!this.anchoredSections) return;

		const removeActiveClass = () => {
			this.menuItems.forEach((menuItem) => {
				menuItem.parentElement.classList.remove('is-active');
			});
		};

		const addActiveClass = (sectionId) => {
			const menuItem = this.menuItems.find( menuItem => menuItem.hash === `#${sectionId}`);
			menuItem.parentElement.classList.add('is-active');
		};

		this.anchoredSections.forEach((section, index) => {
			if (!section) return;

			const scrollTo = {
				duration: 2,
				scrollTo: this.menuItems[index],
				ease: 'power2.inOut',
			}
			ScrollTrigger.create({
				trigger: section,
				start: `top 30%`,
				end: `bottom 30%`,
				refreshPriority: -10,
				onEnter: () => {
					// console.debug('on enter', section.id);
					removeActiveClass();
					addActiveClass(section.id);
					gsap.to(this.$scrollContainerScroller, scrollTo );
				},
				onEnterBack: () => {
					// console.debug('enter back', section.id);
				},
				onLeave: () => {
					// console.dir('on leave', section.id);
				},
				onLeaveBack: () => {
					// console.debug('leave back', section.id, index);
					removeActiveClass();
					if ( index > 0 ) {
						const prevSection = this.anchoredSections[index - 1];
						addActiveClass(prevSection.id);
						gsap.to(this.$scrollContainerScroller, {
							...scrollTo,
							scrollTo: this.menuItems[index - 1],
						} );
					}
				},
				// markers: true,
			});
		});

		setTimeout(() => {
			ScrollTrigger.create({
				trigger: exploreMoreSection,
				start: `top 30%`, // Will be the smaller height header by the time the user gets to the sticky menu
				end: `bottom 30%`,
				refreshPriority: -10,
				onEnter: () => {
					// console.debug('on enter', exploreMoreSection);
					removeActiveClass();
					// addActiveClass(section.id);
					// gsap.to(this.$scrollContainerScroller, scrollTo );
				},
				onEnterBack: () => {
					// console.debug('enter back', exploreMoreSection);
				},
				onLeave: () => {
					// console.debug('on leave', exploreMoreSection);
				},
				onLeaveBack: () => {
					// console.debug('leave back', exploreMoreSection);
					// removeActiveClass();
					const prevSection = this.anchoredSections[this.anchoredSections.length - 1];
					addActiveClass(prevSection.id);
					// gsap.to(this.$scrollContainerScroller, scrollTo );

				},
				// markers: true,
			});
		}, 500);

	}

	anchorLinkGoTo() {
		if (!this.menuItems) return;

		this.menuItems.forEach(link => {
			link.addEventListener('click', (event) => {
				event.preventDefault();
				let targetId = this.getValueAfterHash(link.getAttribute('href'));
				const targetSection = document.querySelector(`#${targetId}`);

				if (targetSection) {
					const targetOffset = targetSection.getBoundingClientRect().top + window.scrollY - this.stickyMenuHeight - 50; // Small header height minus 2px so that it triggers the menu item active state. ScrollTo brings it to just the top of the section.
					window.scrollTo({ top: targetOffset, behavior: isMotionOk() ? 'smooth' : 'instant' });
					// targetSection.scrollIntoView({ behavior: 'smooth' });
					// gsap.to(window,
					// 	{
					// 		duration: 1,
					// 		scrollTo: { y: targetOffset, autoKill: false },
					// 		ease: 'power2.inOut',
					// 	}
					// );
				}
			});
		});
	}

	scrollBar() {
		OverlayScrollbars(this.$scrollContainer, {
			overflow: {
				y: 'hidden',
			},
			scrollbars: {
				theme: 'os-theme-dark',
				autoHide: 'scroll',
			},
		 },
		 {
			initialized: () => {
				this.$scrollContainerScroller = this.$scrollContainer.querySelector('[data-overlayscrollbars-viewport]');
			}
		});
	}
}

customElements.define('anchor-menu', AnchorMenuComponent);
