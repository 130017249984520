/**
 * Wrapper Class for IntersectionObserver API
 * @author bbeeler
 */
export default class {
    /**
     * Create an ElementIntersectionObserver & start observing elements
     * @param {NodeList|Element} elements List of elements or single element to attach observer to
     * @param {Object} observerOptions instance properties to pass to creation of IntersectionObserver
     * @param {Object} callbacks callbacks to trigger at various points of intersection
     * @param {function} callbacks.onIntersect callback to be executed when item enters an intersection threshold
     * @param {function} callbacks.onExit callback to be executed when item exits intersection root
     * @param {function} callbacks.onExitAbove callback to be executed when item exits above intersection root
     * @param {function} callbacks.onExitBelow callback to be executed when item exits below intersection root
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver#instance_properties
     */
    constructor(elements, observerOptions = {}, callbacks = {}) {
        // ensure elements are iterable
        this.elements = (this.isIterable(elements)) ? elements : [ elements ];

        this.root = (observerOptions.hasOwnProperty('root')) ? observerOptions.root : document;

        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if ( entry.isIntersecting && callbacks.hasOwnProperty('onIntersect') ) {
                        callbacks.onIntersect(entry, this.root);
                        return;
                    }

                    if ( callbacks.hasOwnProperty('onExit') ) callbacks.onExit(entry, this.root);

                    if ( callbacks.hasOwnProperty('onExitAbove') && entry.boundingClientRect.top > 0 ) {
                        callbacks.onExitAbove(entry, this.root);
                    } else if ( callbacks.hasOwnProperty('onExitBelow') && entry.boundingClientRect.top < 0 ){
                        callbacks.onExitBelow(entry, this.root);
                    }
                });
            },
            observerOptions
        );

        this.observe();
    }

    observe() {
        this.elements.forEach((el) => this.observer.observe(el));
    }

    unobserve() {
        this.elements.forEach((el) => this.observer.unobserve(el));
    }

    isIterable(obj) {
        return typeof obj[Symbol.iterator] === 'function';
    }
}
