import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


const $brgsSection = document.querySelector('.section--brgs');

const imageAnimation = () => {
	const images = $brgsSection.querySelectorAll('.image');

	if ( !images ) return;

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: images[0],
            start: "top-=200 center",
            end: "bottom center",
            scrub: true,
            // markers: true,
			refreshPriority: -20,
			// once: true
        },
    });

	// Fade in images
    images.forEach((image, index) => {
		tl.to(image, {duration: 0.5, opacity: 1, ease: "power1.inOut" }, index * 0.5 );
    });

	let mm = gsap.matchMedia(),
	breakPoint = 768;

	mm.add({
		// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
		isDesktop: `(min-width: ${breakPoint}px)`,
		isMobile: `(max-width: ${breakPoint - 1}px)`,
		reduceMotion: "(prefers-reduced-motion: reduce)"

	  }, (context) => {

		// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
		let { isDesktop, isMobile, reduceMotion } = context.conditions;

		if ( isDesktop && !reduceMotion ) {
			// Parallax effect
			images.forEach( (image, index) => {
				gsap.to(image, {
					y: -150 * index,
					ease: "none",
					scrollTrigger: {
						trigger: $brgsSection,
						start: "top center",
						end: "bottom top",
						scrub: true,
						refreshPriority: -25,
						// markers: true,
					}
				})
			});
		}

		return () => {
			// optionally return a cleanup function that will be called when none of the conditions match anymore (after having matched)
			// it'll automatically call context.revert() - do NOT do that here . Only put custom cleanup code here.
		}
	});
}

const itemsListAnimation = () => {
	const listItems = $brgsSection.querySelectorAll('.item');

	if ( listItems ) {
		ScrollTrigger.create({
			trigger: $brgsSection,
			start: `top center`,
			end: `bottom bottom`,
			onEnter: () => {
				listItems.forEach((item, index) => {
					setTimeout(() => {
						item.classList.add('is-visible');
					}, 250*index);
				});
			},
			markers: false
		});
	}
}

if ( $brgsSection ) {
	window.addEventListener('load', () => {
		imageAnimation();
		itemsListAnimation();
	});
}



