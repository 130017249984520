import isMotionOk from "../utils/isMotionOk";

document.addEventListener("DOMContentLoaded", function() {
	document.body.classList.remove("no-js");
	document.body.classList.add("js-enabled");

	if ( !isMotionOk() ) {
		document.body.classList.add("reduce-motion");
	}
});
