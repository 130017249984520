// Check if the user is using iOS
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

// Check if the user is using Edge on iOS
const isEdgeOniOS = isIOS && navigator.userAgent.includes('Edg');

// Check if the user is using Firefox on iOS
const isFirefoxOniOS = isIOS && navigator.userAgent.includes('FxiOS');

const classes = [];

const html = document.querySelector('html');

if (isEdgeOniOS) {
  	classes.push('mobile-edge');
} else if (isFirefoxOniOS) {
	classes.push('mobile-firefox');
}

if ( classes.length > 0 ) {
	html.classList.add( classes.join(' ') );
}
