import parallaxEffect from '../../../scripts/utils/parallax';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import isMotionOk from '../../../scripts/utils/isMotionOk';

gsap.registerPlugin( SplitText );

const $heroImageContainer = document.querySelector('.hero__image-container');
const $heroContent = document.querySelector('.hero__content');
const $heroArrow = document.querySelector('.hero__arrow');
const $heroKicker = document.querySelector('.hero__kicker');
const $heroTitle = document.querySelector('.hero__title');
const $heroSubtitle = document.querySelector('.hero__subtitle');
const $heroAction = document.querySelector('.hero__action');

if ( $heroImageContainer ) {
	parallaxEffect($heroImageContainer, 0.5);
}

if ( $heroContent ) {
	parallaxEffect($heroContent, 0.5);
}

if ( $heroArrow ) {
	parallaxEffect($heroArrow, 0.5);
}

const initSplitText = (element) => {
	if (element) {
	  new SplitText(element, { type: 'lines', linesClass: 'line' });
	  new SplitText(element, { type: 'lines', linesClass: 'line-wrapper' });
	}
  };

const heroTitleInit = function () {

	initSplitText($heroKicker);
	initSplitText($heroTitle);
	initSplitText($heroSubtitle);

	const heroLines = document.querySelectorAll('.hero__title .line');

	const heroTL = gsap.timeline();

	if ($heroKicker) {
		const $heroKickerLines = $heroKicker.querySelectorAll('.line');

		heroTL.to(
			$heroKicker,
			{
				opacity: 1,
				duration: 0,
			}
		)
		.to(
			$heroKickerLines,
			{
				y: 0,
				duration: .75,
				ease: 'power2.inOut',
				autoAlpha: 1,
			}
		);
	}

	heroTL.to(
		$heroTitle,
		{
			opacity: 1,
			duration: 0,
		}
	);

	if ($heroKicker) {
		heroTL.to(
			heroLines,
			{
				y: 0,
				stagger: 0.25,
				duration: 1,
				ease: 'power2.inOut',
				autoAlpha: 1,
			}, '-=.5'
		)
	} else {
		heroTL.to(
			heroLines,
			{
				y: 0,
				stagger: 0.25,
				duration: 1,
				ease: 'power2.inOut',
				autoAlpha: 1,
			}
		);
	}

	if ($heroSubtitle) {
		const $heroSubtitleLines = $heroSubtitle.querySelectorAll('.line');

		heroTL.to(
			$heroSubtitle,
			{
				opacity: 1,
				duration: 0,
			}
		)
		.to(
			$heroSubtitleLines,
			{
				y: 0,
				stagger: 0.25,
				duration: .75,
				ease: 'power2.inOut',
				autoAlpha: 1,
			}, '-=.3'
		);
	}

	if ( $heroAction ) {
		const $button = $heroAction.querySelector('.button');
		heroTL.to(
			$heroAction,
			{
				opacity: 1,
				duration: 0,
			}
		)
		.to(
			$button,
			{
				y: 0,
				duration: .75,
				ease: 'power2.inOut',
				autoAlpha: 1,
			}, '-=.5'
		);
	}
}

if ( isMotionOk() ) {
	window.addEventListener( 'load', heroTitleInit );
}


