module.exports = [
    {
        id: 1,
        title: "Defense & Space",
        slug: "defense-space",
        color: "var(--blue)",
        map: ['US'],
		stats: [
			{
				value: '~5,000',
				label: 'tons of chemical weapons destroyed'
			},
			{
				value: '8,900,000',
				label: 'pounds — what NASA’s mobile launcher will be capable of launching into space'
			},
			{
				value: '4 of 9',
				label: 'original U.S. chemical weapons storage depots eliminated '
			}
		],
        image: {
			src: "/assets/images/defense-and-space-hero.jpg",
			caption: "Mobile Launcher 2 project for NASA in Florida, U.S."
		},
    },
    {
        id: 4,
        title: "Environmental Cleanup",
        slug: "environmental-cleanup",
        color: "var(--green)",
        map: ['US', 'CL', 'GB', 'AU', 'PE'],
        stats: [
			{
				value: '2,100<sup>º</sup><span>F</span>',
				label: 'the temperature of the world’s largest nuclear waste melter'
			},
			{
				value: '3.15',
				label: 'megajoules of fusion energy produced at LLNL’s National Ignition Facility'
			},
			{
				value: '3,200',
				label: 'cubic meters of radioactive waste secured at Sellafield’s nuclear facility'
			}
		],
        image: {
			src: "/assets/images/environmental-hero.jpg",
			caption: "Hanford Waste Treatment Plant in Washington, U.S."
		}
    },
	{
        id: 6,
        title: "Critical Minerals",
        slug: "critical-minerals",
        color: "var(--orange)",
        map: ['US', 'CL', 'SA', 'AU', 'CA'],
        stats: [
			{
				value: '200,000,000',
				label: 'metric tons per annum of installed iron ore production'
			},
			{
				value: '30',
				label: 'aluminum smelter projects'
			},
			{
				value: '46',
				label: 'major copper projects'
			}
		],
        image: {
			src: "/assets/images/critical-minerals-hero.jpg",
			caption: "Los Pelambres copper mine in Chile."
		},
    },
	{
        id: 5,
        title: "Advanced Manufacturing",
        slug: "advanced-manufacturing",
        color: "var(--lt-grey)",
        map: ['US', 'BH'],
        stats: [
			{
				value: '2,500,000',
				label: 'square feet of facility at Intel Ohio project'
			},
			{
				value: '30,000',
				label: 'First Student school buses to be powered by electricity'
			},
			{
				value: '9,000',
				label: 'union jobs provided at Shell Polymers Monaca'
			}
		],
        image: {
			src: "/assets/images/Advanced-Manufacturing.jpg",
			caption: "Intel Semiconductor Plant in Ohio, U.S."
		}
    },
	{
        id: 3,
        title: "Infrastructure",
        slug: "infrastructure",
        color: "var(--red)",
        map: ['US', 'CA', 'GB', 'SA', 'IN', 'AU', 'RS'],
        stats: [
			{
				value: '17,200',
				label: 'miles (27,500 km) of highways and roads'
			},
			{
				value: '6,200',
				label: 'miles (10,000 km) of railroads'
			},
			{
				value: '334',
				label: 'projects completed for 189 airports and airport systems '
			}
		],
        image: {
			src: "/assets/images/infrastructure-hero.jpg",
			caption: "Western Sydney Airport."
		}
    },
	{
        id: 7,
        title: "Nuclear Power",
        slug: "nuclear-power",
        color: "var(--purple)",
        map: ['US', 'PL'],
        stats: [
			{
				value: '150',
				label: 'nuclear plants designed and constructed worldwide'
			},
			{
				value: '76,000',
				label: 'megawatts of nuclear generation capacity delivered'
			},
			{
				value: '80%+',
				label: 'of U.S. nuclear plants designed, built, or serviced'
			}
		],
        image: {
			src: "/assets/images/Nuclear-Power.jpg",
			caption: "Plant Vogtle in Georgia, U.S."
		}
    },
    {
        id: 2,
        title: "Energy Security & Transition",
        slug: "energy-security-transition",
        color: "var(--yellow)",
        map: ['US', 'EG', 'AU', 'MX', ],
        stats: [
			{
				value: '52,800',
				label: 'miles (85,000 km) of pipeline'
			},
			{
				value: '30%',
				label: 'of global LNG capacity built and delivered'
			},
			{
				value: '275+',
				label: 'refinery expansions and modernizations'
			}
		],
        image: {
			src: "/assets/images/energy-security-hero.jpg",
			caption: "Port Arthur LNG in Texas, U.S."
		},
    },
];
