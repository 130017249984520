import whatInput from 'what-input';
import Debounce from '../../../scripts/utils/debounce';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const shrinkHeaderOnScroll = () => {
	const header = document.querySelector('.site-header');
	const initialHeight = header.offsetHeight;
	const smallHeight = 50; // Minimum height for the header

	if ( initialHeight !== smallHeight ) {
		window.addEventListener('scroll', () => {
			const scrolledPixels = window.scrollY; // Number of pixels scrolled

			if (scrolledPixels >= smallHeight) {
				header.classList.add('is-small');
				document.documentElement.style.setProperty('--header-height', `${smallHeight}px`);

			} else {
				header.classList.remove('is-small');
				document.documentElement.style.setProperty('--header-height', `${initialHeight}px`);

			}
		});
	}
};

const updateAriaOnDropdown = () => {
	const menuItems = document.querySelectorAll('.menu-item.has-children');

	menuItems.forEach(menuItem => {
		const dropdown = menuItem.querySelector('.sub-menu');

		menuItem.addEventListener('mouseenter', () => {
			menuItem.setAttribute('aria-expanded', 'true');
			dropdown.setAttribute('aria-hidden', 'false');
		});

		menuItem.addEventListener('mouseleave', () => {
			menuItem.setAttribute('aria-expanded', 'false');
			dropdown.setAttribute('aria-hidden', 'true');
		});

		// on touch, toggle the aria-expanded attribute
		menuItem.addEventListener('click', () => {
			const ariaExpanded = menuItem.getAttribute('aria-expanded') === 'true' || false;
			menuItem.setAttribute('aria-expanded', !ariaExpanded);
			dropdown.setAttribute('aria-hidden', ariaExpanded);
		});
	});
};

const setCssProps = (barColor, left, width, container) => {
	container.style.setProperty('--bar-left', `${left}px`);
	container.style.setProperty('--bar-width', `${width}px`);
	container.style.setProperty('--bar-color', `${barColor}`);
}

const positionBar = (menuItems) => {
	const activeBarColor = 'var(--red)';
	const defaultBarColor = 'var(--green)';
	let currentLeft;
	let currentWidth;

	Array.from(menuItems).forEach(item => {
		const isActive = item.classList.contains("is-active") || item.classList.contains("active-ancestor");
		const menuContainer = item.parentNode;

		if (isActive) {
			currentLeft = item.offsetLeft
			currentWidth = item.offsetWidth
			setCssProps(activeBarColor, currentLeft, currentWidth, menuContainer);
		};

		item.addEventListener('mouseenter', event => {
			const hoverBarColor = isActive ? activeBarColor : defaultBarColor;
			setCssProps( hoverBarColor, event.target.offsetLeft, event.target.offsetWidth, menuContainer);
		});

		item.addEventListener('mouseleave', event => {
			setCssProps(activeBarColor, currentLeft, currentWidth, menuContainer);
		});
	});
}

const menuActiveFollower = () => {
	const menu = document.querySelector('.navigation > .menu');
	const menuItems = menu.children;

	menu.classList.add('is-ready');

	positionBar(menuItems);

	window.addEventListener('resize', Debounce( () => {
		positionBar(menuItems);
	}, 100));
};

const touchFunctionality = () => {

	if ( whatInput.ask() !== 'touch') return;

	const menuItems = document.querySelectorAll('.site-header .menu-item');

	menuItems.forEach($menuItem => {
		const $submenu = $menuItem.querySelector('.sub-menu');
		const hasChildren = $menuItem.classList.contains('has-children');

		if (hasChildren) {
			$menuItem.addEventListener('click', event => {
				event.preventDefault();

				$menuItem.classList.toggle('show-sub-menu');
			});

			$submenu.addEventListener('click', event => {
				event.stopPropagation();
			});

			// This will close the sub-menu
			document.addEventListener('click', event => {
				if (!$menuItem.contains(event.target) && !$submenu.contains(event.target)) {

					const $expandedMenuItem = document.querySelector('.show-sub-menu');
					if ( $expandedMenuItem ) {
						$expandedMenuItem.classList.remove('show-sub-menu');
					}
				}
			});
		}
	});
};

const hideHeader = () => {
	const $header = document.querySelector('.site-header');
	const headerHeight = 50; // when the user gets to the footer, the small header is shown.
	const $footer = document.querySelector('.site-footer');
	const hasAnchor = document.querySelector('.anchor-menu-container');
	const offset = hasAnchor ? 4 : 0;

	setTimeout(() => {
		ScrollTrigger.create({
			trigger: $footer,
			start: `top+=${offset}px top`,
			end: `${headerHeight}px top`,
			refreshPriority: -15,
			scrub: 0,
			// markers: true,
			// onUpdate: (self) => {
			// 	const progress = self.progress;

			// 	gsap.to($header, {
			// 		y: -headerHeight * progress,
			// 	});
			// },
			onEnter () {
				$header.classList.add('slide-up');
			},
			onLeaveBack () {
				$header.classList.remove('slide-up');
			}
		});
	}, 200);
};

document.addEventListener('DOMContentLoaded', () => {
	updateAriaOnDropdown();
	touchFunctionality();
});

window.addEventListener('load', () => {
	shrinkHeaderOnScroll();
	menuActiveFollower();
	hideHeader();
});
