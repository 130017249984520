
import Debounce from '../../../scripts/utils/debounce';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const backgrounds = document.querySelectorAll( '.bg-start' );

const getBgEnd = ($elm) => {
	let $endDiv = $elm.nextSibling;

	while ( $endDiv ) {

		// skip text nodes
		if ( $endDiv.nodeType !== 3 && $endDiv.classList.contains( 'bg-end' ) ) {
			break;
		}
		$endDiv = $endDiv.nextSibling;
	}

	return $endDiv;
}

const setBackgroundHeight = ($elm) => {
	const $background = $elm.querySelector( '.background' );
	const $startDiv = $elm;
	let $endDiv = getBgEnd($startDiv);

	// set the grid width and height based on the distance between the two divs
	const gridHeight = $endDiv.offsetTop - $startDiv.offsetTop;
	$background.style.height = gridHeight + 'px';

	animateBackground($elm);
}

const animateBackground = ($startDiv) => {
	const $background = $startDiv.querySelector( '.background' );
	let $sectionBefore = $startDiv.previousSibling;
	if ( $sectionBefore.classList.contains('bg-end') ) {
		$sectionBefore = $sectionBefore.previousSibling;
	}
	let $nextSection = $startDiv.nextSibling;
	if ( $nextSection.classList.contains('bg-start') ) {
		$nextSection = $nextSection.nextSibling;
	}
	const background = $startDiv.dataset.background;

	// console.log('$sectionBefore', $sectionBefore);
	// console.log('$nextSection', $nextSection);

	const topOffest = $sectionBefore ? `top top+=${$sectionBefore.clientHeight/2}` : '50%';
	const bottomOffset = $nextSection ? `bottom bottom-=${$nextSection.clientHeight/2}` : '50%';

	const timeline = gsap.timeline({
		scrollTrigger: {
			trigger: $background,
			start: topOffest,
			end: bottomOffset,
			scrub: true,
			// markers: true,
			toggleClass: {
				targets: $globalBg,
				className: background,
			},
		},
	});
}
