import { slideDown, slideToggle } from '../../../scripts/utils/slide';

const toggleMobileMenu = () => {
	const mobileTrigger = document.querySelector('.mobile-trigger');
	const siteHeader = document.querySelector('.site-header');
	const menu = document.querySelector('.mobile-menu');

	mobileTrigger.addEventListener('click', () => {
		mobileTrigger.classList.toggle('is-active');
		siteHeader.classList.toggle('mobile-menu-expanded');
		menu.classList.toggle('is-expanded');
	});
};

const expandSubMenus = () => {
	const subMenuTriggers = document.querySelectorAll('.mobile-menu .dropdown-toggle');

	if ( ! subMenuTriggers) return;

	subMenuTriggers.forEach($trigger => {
		const $submenu = $trigger.parentElement.querySelector('.sub-menu');
		const $submenuParent = $submenu.parentElement;
		const classlist = $submenuParent.classList;

		if ( classlist.contains('has-children') && classlist.contains('active-ancestor') ) {
			slideDown($submenu);
			$submenu.classList.add('is-expanded');
			$submenu.previousElementSibling.classList.add('is-active');
		}

		$trigger.addEventListener('click', (e) => {
			$trigger.classList.toggle('is-active');
			slideToggle($submenu);
			$submenu.classList.toggle('is-expanded');
		});
	});

};

toggleMobileMenu();
expandSubMenus();
