import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Create a callback function to handle intersection changes
const intersectionCallback = (entries, observer) => {
	entries.forEach((entry) => {
		// Check if the section is in the viewport
		if (entry.isIntersecting) {
			// Add the 'is-visible' class
			entry.target.classList.add('is-visible');
			// Stop observing this section after it becomes visible (optional)
			observer.unobserve(entry.target);
		}
	});
};

const observer = new IntersectionObserver(intersectionCallback);

function setActiveClass(sections) {
	sections.forEach(section => {
		observer.observe(section);

		// ScrollTrigger.create({
		// 	trigger: section,
		// 	// markers: true,
		// 	start: "top bottom-=15%",
		// 	end: "top top",
		// 	onEnter: function() {
		// 		section.classList.add('is-visible');
		// 	},
		// 	once: true
		// });
	});
}
const ActiveSection = () => {

	const sections = document.querySelectorAll('.section');

	if ( sections ) {
		window.addEventListener("load", setActiveClass(sections));
	}
}

ActiveSection();
