import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Debounce from '../../../scripts/utils/debounce';
import isMotionOk from '../../../scripts/utils/isMotionOk';

Chart.register(ChartDataLabels);
gsap.registerPlugin(ScrollTrigger);

const $chartSection = document.querySelector('.section--our-people-chart');

const usColors = {
	backgroundColor: '#EA6966',
	hoverBackgroundColor: '#EA6966',
}

const bechtelColors = {
	backgroundColor: '#245796',
	hoverBackgroundColor: '#245796',
}

if ( $chartSection ) {
	const $ourPeopleChart = $chartSection && $chartSection.querySelector('#our-people-chart');
	const toggleButtons = $chartSection && $chartSection.querySelectorAll('.button');
	const $btnToggleLostTime = document.querySelector('.btn-toggle-lost-time');
	const $btnToggleTotalRecordable = document.querySelector('.btn-toggle-total-recordable');
	let chart;
	let delayed;
	let fontSize;

	const updateButton = (button) => {
		toggleButtons.forEach(btn => btn.classList.remove('is-active'));
		button.classList.add('is-active');
	}

	const initialData = [
		{
			label: 'U.S. Bureau of Labor Statistics',
			data: [0, 0, 0, 0, 0, 0, 0, 0],
			...usColors,
			datalabels: {
				color: '#fff',
				anchor: 'end',
				align: 'start',
			},
		},
		{
			label: 'Bechtel',
			data: [0, 0, 0, 0, 0, 0, 0, 0],
			...bechtelColors,
			datalabels: {
				color: '#245796',
				anchor: 'end',
				align: 'end',
				font: {
					weight: '700',
					size: 20,
				},
			},
		},
	];

	const lostTimeData = [
		{
			label: 'U.S. Bureau of Labor Statistics',
			data: [1.3, 1.2, 1.2, 1.1, 1.1, 1.1, 1.0, 1.0],
			...usColors,
			datalabels: {
				color: '#fff',
				anchor: 'end',
				align: 'start',
			},
		},
		{
			label: 'Bechtel',
			data: [0.037, 0.026, 0.032, 0.037, 0.016, 0.014, 0.015, 0.014],
			...bechtelColors,
			datalabels: {
				color: '#245796',
				anchor: 'end',
				align: 'end',
				font: {
					weight: '700',
					size: 20,
				},
			},
		},
	];

	const totalRecordableData = [
		{
			label: 'U.S. Bureau of Labor Statistics',
			data: [3.2, 3.1, 3.0, 2.8, 2.5, 2.5, 2.4, 2.4],
			...usColors,
			datalabels: {
				color: '#fff',
				anchor: 'end',
				align: 'start',
			},
		},
		{
			label: 'Bechtel',
			data: [0.337, 0.299, 0.284, 0.304, 0.244, 0.228, 0.192, 0.163],
			...bechtelColors,
			datalabels: {
				color: '#245796',
				anchor: 'end',
				align: 'end',
				font: {
					weight: '700',
					size: 20,
				},
			},
		},
	];

	const updateChart = (newData) => {
		chart.data.datasets.forEach((dataset, index) => {
			dataset.data = newData[index].data;
		});
		chart.update();
	}

	const chartConfig = {
		type: 'bar',
		data: {
			labels: ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
			datasets: initialData
		},
		options: {
			scales: {
				x: {
					grid: {
						display: false,
					},
				},
				y: {
					beginAtZero: true,
					ticks: {
						stepSize: 0.5,
						padding: 10,
					},
					border: {
						display: false,
					},
				}
			},
			animation: {
				onComplete: () => {
					delayed = true;
				},
				delay: (context) => {
					let delay = 0;
					if (context.type === 'data' && context.mode === 'default' && !delayed) {
						delay = context.dataIndex * 300 + context.datasetIndex * 100;
					}
					return delay;
				},
				duration: isMotionOk() ? 1000 : 0,
			},
			responsive: true,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
				datalabels: {
					padding: 6,
					formatter: function(value, context) {
						if (value % 1 === 0) {
							return value.toFixed(1);
						}
						return value;
					},
				},
			},
			onResize: function(chart, size) {
				if (size.width <= 768) {
					chart.options.scales.y.display = false;
					chart.options.plugins.datalabels.font.size = 10;
				} else {
					chart.options.scales.y.display = true;
				}
			},
		}
	};

	const chartInit = () => {
		if ( isMotionOk() ) {
			ScrollTrigger.create({
				trigger: $chartSection,
				start: "top top+=30%",
				end: "bottom center",
				onEnter: () => {
					updateChart(lostTimeData);
				},
				once: true,
				// markers: true
			});
		} else {
			updateChart(lostTimeData);
		}

		const isMobile = window.innerWidth <= 768;

		fontSize = isMobile ? 10 : 20;

		initialData[1].datalabels.font.size = fontSize;
		lostTimeData[1].datalabels.font.size = fontSize;
		totalRecordableData[1].datalabels.font.size = fontSize;

		updateChart(initialData);
	};

	$btnToggleLostTime.addEventListener('click', () => {
		updateChart(lostTimeData);
		updateButton($btnToggleLostTime);
	});

	$btnToggleTotalRecordable.addEventListener('click', () => {
		updateChart(totalRecordableData);
		updateButton($btnToggleTotalRecordable);
	});

	document.addEventListener('DOMContentLoaded', () => {
		chart = new Chart($ourPeopleChart, chartConfig);
	});

	window.addEventListener('load', () => {
		chartInit();
	});

	window.addEventListener('resize', Debounce( () => {
		const isMobile = window.innerWidth <= 768;

		fontSize = isMobile ? 10 : 20;

		initialData[1].datalabels.font.size = fontSize;
		lostTimeData[1].datalabels.font.size = fontSize;
		totalRecordableData[1].datalabels.font.size = fontSize;

		chart.update();
	}, 100) );
}
