import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import isMotionOk from '../../../scripts/utils/isMotionOk';
import Debounce from '../../../scripts/utils/debounce';

gsap.registerPlugin(ScrollTrigger, SplitText);

const DARKGREEN = "rgb(48, 69, 76)";
const SLATE = "rgb(87, 114, 127)";
const WHITE = "rgb(255, 255, 255)";

const createColorChangeTimeline = (elm, color, start, end) => {
    return gsap.timeline({
        scrollTrigger: {
            trigger: elm,
            start: start,
            end: end,
            toggleActions: "restart pause restart none",
            scrub: 1,
			once: true
        }
    }).to(elm, {
        // color: color,
		opacity: 1
    });
};

const animateTransition = ($blockquoteSection) => {
	const $quoteContainer = $blockquoteSection.querySelector('.quote-container');

	window.addEventListener('load', () => {

		let mm = gsap.matchMedia(),
    	breakPoint = 768;

		mm.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: $quoteContainer,
					start: isMobile ? `top 60%` : `top 50%`,
					end: "bottom-=90% top+=30%",
					scrub: 1,
					// markers: true,
					refreshPriority: -10,
					onUpdate: (self) => {
						const progress = self.progress;
						gsap.to($quoteContainer, {
							opacity: progress,
						});
					},
				},
			});

			return () => {
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});
	});
};

const animateQuote = ($blockquoteSection) => {

	if ( !isMotionOk() ) return;

	const $quote = $blockquoteSection.querySelector(".quote");
	const $text = $blockquoteSection.querySelector(".quote__text");
	const $span = $text.querySelector("span");
	const quoteDeco = $blockquoteSection.querySelectorAll(".quote-deco");
	const $cite = $blockquoteSection.querySelector(".quote__cite");
	const $blockquoteBechtel1 = $blockquoteSection.classList.contains("blockquote--bechtel-1");
	let split;

	const animate = () => {

		quoteDeco.forEach((deco, i) => {
			// Last quote
			let start = "top 80%";
			let end = "bottom 90%";
			if ( i === 0 ) { // First quote
				start = "top 70%";
				end = "bottom 90%";
			}

			createColorChangeTimeline(deco, SLATE, start, end)
		});

		const splitTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: $quote,
				start: "top 70%",
				end: "bottom 90%",
				scrub: 1,
				once: true,
				// markers: true
			}
		});

		split = new SplitText($text, {
			charsClass: 'char',
		});

		splitTimeline.to($text.querySelectorAll('.char, span'), {
			stagger: 0.02,
			opacity: 1,
			onComplete: () => {
				$blockquoteSection.classList.add('animation-complete');
				// split.revert();
			},
		});

		if ($cite) {
			const citeTl = createColorChangeTimeline($cite, DARKGREEN);
		}
	}


	window.addEventListener('load', () => {
		animate({
			$blockquoteSection,
			$quote,
			$text,
			$span,
			quoteDeco,
			$cite,
			$blockquoteBechtel1,
			split,
		});
	});

	const isTouchDevice = () => {
		return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
	};

	if ( ! isTouchDevice() ){
		window.addEventListener(
			'resize',
			Debounce(() => {
				if(split) {
					split.revert();
					split = new SplitText($text);
				}

				ScrollTrigger.refresh();
			}, 100)
		);
	}
};

const $blockquoteSections = document.querySelectorAll('.section--blockquote');

if ( $blockquoteSections ) {
	$blockquoteSections.forEach( $blockquoteSection => {
		if ( $blockquoteSection.classList.contains('has-image')) {
			animateTransition($blockquoteSection);
		} else {
			animateQuote($blockquoteSection);
		}
	} );
}



