/**
 * Card Project
 *
 * The reason this card is in javascript and not njk is because we can't use njk templates in javascript. This card is used on the projects hub, which is driven by javascript. It was easier to create a js template vs rendering the cards out in njk, then using javascript to hide/show the correct cards.
 *
 * @param   {object}  data  Card Data
 *
 * @return  {html}        Card HTML
 */
const cardProject = (data) =>{
    return `
        <div class="card card--project">
            <div class="card__location">${data.location}</div>
            <h2 class="card__title">${data.title}</h2>
            <div class="card__teaser">${data.teaser}</div>
        </div>
    `
}

export default cardProject;
