import ElementIntersectionObserver from "../../../scripts/utils/ElementIntersectionObserver"
// TODO
import isMotionOk from '../../../scripts/utils/isMotionOk';

class ScrollSlider
{
	el
	slides
	dots
	images
	observer

	constructor(el)
	{
		this.el = el

		this.items = Array.from(this.el.querySelectorAll('.scroll-slider__item'))
		this.dots = Array.from(this.el.querySelectorAll('.scroll-slider__dot'))
		this.images = Array.from(this.el.querySelectorAll('.scroll-slider__images picture'))
		this.contents = Array.from(this.el.querySelectorAll('.scroll-slider__content'))

		if ([this.items, this.dots, this.images, this.contents].find(a => a.length === 0))
		{
			console.warn('No items found for scroll slider')
			return
		}

		// 0.1 -> 1.0, (trigger 10 times)
		const threshold = Array.from({length: 10}, (_, i) => ((i + 1) / 10))
		this.observer = new ElementIntersectionObserver(this.items, {
			root: null,
			rootMargin: '0px 0px -15% 0px',
			threshold,
		}, {
			onIntersect: entry => {
				const idx = this.items.indexOf(entry.target)

				// this.updateImage(idx, (entry.intersectionRatio * 2))
				this.updateImage(idx, (2))
				this.updateContent(idx, (entry.intersectionRatio * 2))
				this.updateDot(idx, true)
			},
			onExitAbove: entry => {
				const idx = this.items.indexOf(entry.target)

				this.updateImage(idx, 0)
				this.updateContent(idx, 0)
				this.updateDot(idx, false)
			},
			onExitBelow: entry => {
				const idx = this.items.indexOf(entry.target)

				this.updateImage(idx, 1)
				this.updateContent(idx, 1)
				this.updateDot(idx, true)
			},
		})
	}

	updateImage(idx, value)
	{
		this.images[idx].style.opacity = value;
	}

	updateContent(idx, value)
	{
		this.contents[idx].style.opacity = value;
	}

	updateDot(idx, on)
	{
		this.dots[idx].classList.toggle('scroll-slider__dot--active', on)
	}
}

document.querySelectorAll('.scroll-slider').forEach(section => section.scrollSlider = new ScrollSlider(section))
