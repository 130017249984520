import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Debounce from '../../../scripts/utils/debounce';
import isMotionOk from '../../../scripts/utils/isMotionOk';

Chart.register(ChartDataLabels);
gsap.registerPlugin(ScrollTrigger);

const $chartSection = document.querySelector('.section--chart-our-communities');

if ( $chartSection ) {
	const $chart = $chartSection && $chartSection.querySelector('#our-communities-chart');

	let chart;
	let delayed;

	const updateChart = (newData) => {
		chart.data.datasets.forEach((dataset, index) => {
			dataset.data = newData[index].data;
		});
		chart.update();
	}

	const initialData = [
		{
			data: [14.2, 16.6, 6, 9.3, 11.6, 15.3],
			backgroundColor: '#245796',
			hoverBackgroundColor: '#245796',
			datalabels: {
				color: '#fff',
				anchor: 'end',
				align: 'start',
			},
		}
	];

	const chartConfig = {
		type: 'bar',
		data: {
			labels: ['2018', '2019', '2020', '2021', '2022','2023'],
			datasets: [
				{
					data: [0, 0, 0, 0, 0, 0],
					backgroundColor: '#245796',
					hoverBackgroundColor: '#245796',
					datalabels: {
						color: '#fff',
						anchor: 'end',
						align: 'start',
					},
				}
			],
		},
		options: {
			maxBarThickness: 100,
			scales: {
				x: {
					grid: {
						display: false,
					},
					ticks: {
						color: 'var(--slate)',
						font: {
							size: 16,
							style: 'normal',
							lineHeight: 1.2,
							fontWeight: 500,
							letterSpacing: '-0.16px',
						},
					},
				},
				y: {
					beginAtZero: true,
					ticks: {
						stepSize: 5,
						padding: 10,
					},
					border: {
						display: false,
					},
					title: {
						display: true,
						text: 'Annual Spend (USD) Billions',
						color: 'var(--dark-green)',
						font: {
							size: 20,
							style: 'normal',
							lineHeight: 1.2,
							fontWeight: 600,
							letterSpacing: '1.6px',
						},
					},
					min: 0,
					max: 20,
				}
			},
			animation: {
				onComplete: () => {
					delayed = true;
				},
				delay: (context) => {
					let delay = 0;
					if (context.type === 'data' && context.mode === 'default' && !delayed) {
						delay = context.dataIndex * 300 + context.datasetIndex * 100;
					}
					return delay;
				},
				duration: isMotionOk() ? 1000 : 0,
			},
			responsive: true,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			onResize: function(chart, size) {
				if (size.width <= 768) {
					chart.options.scales.y.display = false;
					chart.options.scales.x.title = {
						display: true,
						text: 'Annual Spend (USD) Billions',
						color: 'var(--dark-green)',
						font: {
							size: 20,
							style: 'normal',
							lineHeight: 1.2,
							fontWeight: 600,
							letterSpacing: '1.6px',
						},
						padding: {
							top: 30
						}
					}
				} else {
					chart.options.scales.y.display = true;
					chart.options.scales.x.title.display = false;

				}
			},
		}
	};

	document.addEventListener('DOMContentLoaded', () => {
		chart = new Chart($chart, chartConfig);

		if ( isMotionOk() ) {
			ScrollTrigger.create({
				trigger: $chartSection,
				start: "top top+=30%",
				end: "bottom center",
				onEnter: () => {
					updateChart(initialData);
				},
				once: true,
				// markers: true
			});
		} else {
			updateChart(initialData);
		}
	});
}
