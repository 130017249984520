import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Debounce from '../../../scripts/utils/debounce';
import isMotionOk from '../../../scripts/utils/isMotionOk';

Chart.register(ChartDataLabels);
gsap.registerPlugin(ScrollTrigger);

const $chartSection = document.querySelector('.section--chart-our-impact');

if ( $chartSection ) {
	const $chart = $chartSection && $chartSection.querySelector('#our-impact-chart');
	const $chartContainer = $chartSection.querySelector('.chart-container');

	let chart;
	let delayed;

	const updateChart = (newData) => {
		chart.data = newData;
		chart.update();
	}

	const initialData = {
		labels: ['2023','2022', '2021', '2020'],
		datasets: [
			{
				data: [0,0,0,0],
				borderColor: 'transparent',
      			backgroundColor: '#EA6966',
				hoverBackgroundColor: "#EA6966"
			},
			{
				data: [0,0,0,0],
				borderColor: 'transparent',
      			backgroundColor: '#EA6966',
				hoverBackgroundColor: "#EA6966"
			},
			{
				data: [0,0,0,0],
				borderColor: 'transparent',
				backgroundColor: '#8AB08A',
				hoverBackgroundColor: "#8AB08A"
			},
			{
				data: [0,0,0,0],
				borderColor: 'transparent',
				backgroundColor: '#BAB0D4',
				hoverBackgroundColor: "#BAB0D4"
			},
			{
				data: [0,0,0,0],
				borderColor: 'transparent',
				backgroundColor: '#F58D18',
				hoverBackgroundColor: "#F58D18"
			}
		]
	};

	const data = {
		labels: ['2023','2022', '2021', '2020'],
		// These data points are transposed, so e.g. the first object is the first bar in each year, etc
		datasets: [
			{
				data: [20.6,16.8,17.5,17.6],
				borderColor: 'transparent',
				backgroundColor: '#EA6966',
				hoverBackgroundColor: "#EA6966"
			},
			{
				data: [16.4,12.6,12.2,12.7],
				borderColor: 'transparent',
				backgroundColor: '#8AB08A',
				hoverBackgroundColor: "#8AB08A"
			},
			{
				data: [30.5,30.1,8.5,7.1],
				borderColor: 'transparent',
				backgroundColor: '#BAB0D4',
				hoverBackgroundColor: "#BAB0D4"
			},
			{
				data: [60.3,44.7,27.8,32.2],
				borderColor: 'transparent',
				backgroundColor: '#F58D18',
				hoverBackgroundColor: "#F58D18"
			}
		]
	};


	const chartConfig = {
		type: 'bar',
		data: initialData,
		options: {
			maxBarThickness: 100,
			indexAxis: 'y',
			// Elements options apply to all of the options unless overridden in a dataset
			// In this case, we are setting the border of each horizontal bar to be 2px wide
			scales: {
				x: {
					grid: {
						display: false,
					},
					ticks: {
						color: 'var(--slate)',
						font: {
							size: 16,
							style: 'normal',
							lineHeight: 1.2,
							fontWeight: 500,
							letterSpacing: '-0.16px',
						},
					},
				},
				y: {
					beginAtZero: true,
					border: {
						display: false,
					},
					title: {
						display: false,
					},
					grid: {
						display: false
					},
					ticks: {
						color: '#57727F',
						font: {
							size: 16,
							style: 'normal',
							lineHeight: 1.2,
							fontWeight: 500,
							letterSpacing: '-0.16px',
						},
					},

				},
				x: {
					position: 'top',
					beginAtZero: true,
					ticks: {
						stepSize: 10,
						padding: 10,
						color: '#57727F',
						font: {
							size: 14,
							style: 'normal',
							lineHeight: 1.2,
							fontWeight: 500,
							letterSpacing: '-0.14px',
						},
					},
					border: {
						display: false,
					},
					title: {
						display: false,
					},
					min: 0,
					max: 70,
				}
			},
			animation: {
				onComplete: () => {
					delayed = true;
				},
				delay: (context) => {
					let delay = 0;
					if (context.type === 'data' && context.mode === 'default' && !delayed) {
						delay = context.dataIndex * 300 + context.datasetIndex * 100;
					}
					return delay;
				},
				duration: isMotionOk() ? 1000 : 0,
			},
			responsive: true,
			maintainAspectRatio: false,
			// onResize: function(chart, size) {
			// 	if (size.width <= 300) {
			// 		console.debug('bug', size.width);
			// 		chart.options.scales.y.maxRotation = 0;
			// 		chart.options.scales.y.minRotation = 0;
			// 	} else {
			// 		console.debug('small', size.width);
			// 		chart.options.scales.y.maxRotation = 90;
			// 		chart.options.scales.y.minRotation = 90;
			// 	}
			// },
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
				title: {
					display: true,
					text: 'Billions of U.S. dollars',
					color: 'var(--dark-green)',
					font: {
						size: 20,
						style: 'normal',
						lineHeight: 1.2,
						fontWeight: 600,
						letterSpacing: '1.6px',
					},
					padding: {
						bottom: 40
					}
				},
				datalabels: {
					color: '#000',
					anchor: 'end',
					align: 'right',
					formatter: (value) => {
						if (value < 10) return '';
						return value;
					},
				},
			},
		},
	};

	document.addEventListener("DOMContentLoaded", function() {
		chart = new Chart($chart, chartConfig);

		if ( isMotionOk() ) {
			ScrollTrigger.create({
				trigger: $chartSection,
				start: "top top+=30%",
				end: "bottom center",
				onEnter: () => {
					updateChart(data);
				},
				once: true,
				// markers: true
			});
		} else {
			updateChart(data);
		}
	});
}
