import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import isMotionOk from '../../../scripts/utils/isMotionOk';
import Debounce from '../../../scripts/utils/debounce';

gsap.registerPlugin( SplitText );

const duration = isMotionOk() ? 0.8 : 0;

const animateTextInit = function () {
	const sections = document.querySelectorAll('.section--image-text.is-large');

	if ( sections.length === 0 ) return;

	sections.forEach( ( $section ) => {
		let $content = $section.querySelector( '.content p' );

		if ( ! $content ) return;

		const splitTimeline = gsap.timeline({
			scrollTrigger: {
			  trigger: $section,
			  start: "top 75%",
			  end: "bottom 90%",
			  // markers: true,
			//   scrub: 1,
			  once: true
			}
		});


		const splitLine1 = new SplitText($content, { type: "lines", linesClass: "line" });
		const splitLine2 = new SplitText($content, { type: "lines", linesClass: "line-wrapper" });

		const lines = $content.querySelectorAll('.line');
		// gsap.set( lines, { autoAlpha: 0 } );
		splitTimeline.set( $content, { autoAlpha: 1 } );
		splitTimeline.to( lines, { autoAlpha: 1, duration: 0 } );
		splitTimeline.from(
            lines,
            {
                y: 40,
				stagger: 0.1,
				duration,
				ease: 'power2.inOut',
				autoAlpha: 1,
				onComplete: () => {
					splitLine2.revert();
					splitLine1.revert();
				}
            }
        );
	});
};

window.addEventListener( 'load', animateTextInit );

