import MicroModal from 'micromodal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Player from '@vimeo/player';

/**
 * Modal
 *
 * @class
 */

const $modals = document.querySelectorAll('.modal');

if ( $modals ) {

	const body = document.querySelector('body');
	MicroModal.init({
		onShow: (modal, element) => {
			console.log('open', modal);
			disableBodyScroll(modal);
		},
		onClose: (modal, element) => {
			console.log('close');
			// If modal contains video, pause it
			const $modalVimeoIframe = modal.querySelector('.vimeo iframe');
			const $modalYoutubeIframe = modal.querySelector('.youtube iframe');

			if ( $modalVimeoIframe ) {
				const player = new Player($modalVimeoIframe);
				player.pause();
			}

			if ( $modalYoutubeIframe ) {
				// var youtubePlayer = new YT.Player($modalYoutubeIframe);
				// youtubePlayer.stopVideo();
				$modalYoutubeIframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');

					// console.log(' window.YT.ready(function() {');
					// const youtubePlayer = new YT.Player('our-people-video-modal-1');
					// youtubePlayer.stopVideo();
					// console.log(youtubePlayer);

			}
			enableBodyScroll(modal);

		},
		awaitOpenAnimation: true,
		awaitCloseAnimation: true,
		// disableScroll: true,
	});

}
