import Macy from 'macy';
import projectCategories from '../../../../data/projectCategories';
import projects from '../../../../data/projects';
import cardProject from '../cards/card-project';

const $sectionProjects = document.querySelector('.section--projects');
const $filterContainer = document.querySelector('.anchor-menu-container');
let masonry = null;

const updateBrowserUrl = (category) => {
	window.location.hash = category;
};

const SetActiveState = (filters, $filter) => {
	filters.forEach(($filter) => {
		$filter.classList.remove('is-active');
	});

	updateBrowserUrl($filter.dataset.category);

	$filter.classList.add('is-active');
};

const updateMap = (category, container) => {
	// Reset previously highlighted countries
	container.querySelectorAll(`path`).forEach((country) => {
		country.style.fill = 'var(--map-country-color)';
	});

	category.map.forEach((countryCode) => {
		const $country = container.querySelector(`#${countryCode}`);
		if ($country) {
			$country.style.fill = category.color;
		}
	});
};

const updateStats = (
	category,
	$container,
	fadeInDuration = 500,
	fadeOutDuration = 500
) => {
	const stats = category.stats;
	const $statContainers = Array.from($container.querySelectorAll('.stat'));

	const fadeOutStats = () => {
		return new Promise((resolve) => {
			const fadeOutStep = fadeOutDuration / $statContainers.length;
			$statContainers.forEach(($statContainer, i) => {
				setTimeout(() => {
					$statContainer.classList.remove('is-visible');
					if (i === $statContainers.length - 1) {
						resolve();
					}
				}, i * fadeOutStep);
			});
		});
	};

	const updateAndFadeInStats = () => {
		return new Promise((resolve) => {
			const fadeInStep = fadeInDuration / stats.length;

			stats.forEach((stat, i) => {
				setTimeout(() => {
					const $statContainer = $statContainers[i];
					const $value = $statContainer.querySelector('.stat__value');
					const $label = $statContainer.querySelector('.stat__label');

					$value.style.color = category.color;
					$value.innerHTML = stat.value;
					$label.innerHTML = stat.label;
					$statContainer.classList.add('is-visible');

					if (i === stats.length - 1) {
						resolve();
					}
				}, i * fadeInStep);
			});
		});
	};

	fadeOutStats().then(() => {
		updateAndFadeInStats();
	});
};

const updateCategoryData = (category, container) => {
	const $title = container.querySelector('.category-title');

	$title.innerHTML = category.title;
	$title.style.color = category.color;

	updateStats(category, container);
};

const slideTpl = (image) => {
	const caption = image.caption ? `<p class="caption">${image.caption}</p>` : '';
	return `
		<div class="project-image">
			<img src="${image.src}" alt="${image.caption}" />
			${caption}
		</div>
	`;
};

const updateImages = (category, $imageContainer) => {
	$imageContainer.classList.add('fade-out');
	setTimeout(() => {
		$imageContainer.innerHTML = slideTpl(category.image);
		setTimeout(() => {
			$imageContainer.classList.remove('fade-out');
		}, 200);
	}, 150);
};

const updateProjects = (category, container) => {
	// find projects that have the same category id
	const categoryProjects = projects.filter(
		(project) => project.category === category.id
	);
	const projectsHtml = categoryProjects
		.map((project) => cardProject(project))
		.join('');

	container.innerHTML = projectsHtml;

	masonry.recalculate(true);

	const newProjects = container.querySelectorAll('.card--project');

	newProjects.forEach((project, index) => {
		setTimeout(() => {
			project.classList.add('is-visible');
		}, 250 * index);
	});
};

const initModules = (pageElements) => {
	masonry = Macy({
		container: pageElements.$projectList,
		columns: 2,
		trueOrder: true,
		margin: {
			x: 30,
			y: 40,
		},
		useContainerForBreakpoints: true,
		mobileFirst: true,
		breakAt: {
			0: 1,
			550: {
				margin: {
					x: 90,
					y: 40,
				},
				columns: 2,
			},
		},
	});
};

const setCurrentCategory = (category, pageElements) => {
	updateMap(category, pageElements.$map);
	updateCategoryData(category, pageElements.$categoryData);
	updateImages(category, pageElements.$imageContainer);
	updateProjects(category, pageElements.$projectList);
};

const getCategoryData = (categorySlug) => {
	return projectCategories.filter(
		(category) => category.slug === categorySlug
	)[0];
};

if ($sectionProjects && $filterContainer) {
	const updateFilterOffsetScroll = () => {
		const h = document.querySelector('.project-map');
		const b = document.querySelector('.site-header');

		if (!h || !b) {
			window.filterOffset = 0;
		}

		window.filterOffsetScroll = h.clientHeight - b.clientHeight;
	};
	updateFilterOffsetScroll();
	window.addEventListener('resize', updateFilterOffsetScroll);

	const scrollToTopOfFilter = (cb) => {
		updateFilterOffsetScroll();

		// if we haven't scrolled past the filter,
		// dont do anything so we can see the map change
		// but still return a promise so we can callback
		if (window.pageYOffset <= window.filterOffsetScroll) {
			cb();
			return;
		}

		window.scrollTo({
			top: window.filterOffsetScroll,
			behavior: 'smooth',
		});

		setTimeout(() => cb(), 500);
	};

	const filters = $filterContainer.querySelectorAll('.button--filter');
	let $onLoadActiveFilter = $filterContainer.querySelector(
		'.button--filter.is-active'
	);
	let currentCategory;
	let hashFilter;
	const pageElements = {
		$map: document.querySelector('.project-map svg'),
		$imageContainer: $sectionProjects.querySelector(
			'.project-image-container'
		),
		$categoryData: $sectionProjects.querySelector('.category-data'),
		$projectList: $sectionProjects.querySelector('.project-list'),
	};

	// detect if the url has a hashtag, if so get the value of the hash tag and set it as the active filter on page load
	const hashtag = window.location.hash;

	if (hashtag) {
		hashFilter = Array.from(filters).filter(
			($filter) => $filter.dataset.category === hashtag.replace('#', '')
		)[0];
		currentCategory = getCategoryData(hashtag.replace('#', ''));
	} else {
		currentCategory = getCategoryData($onLoadActiveFilter.dataset.category);
	}

	initModules(pageElements);

	setCurrentCategory(currentCategory, pageElements);
	SetActiveState(filters, hashFilter || $onLoadActiveFilter);

	filters.forEach(($filter) => {
		$filter.addEventListener('click', (event) => {
			// dont do anything if it's already active
			if (window.location.hash === `#${$filter.dataset.category}`) {
				return;
			}

			// scroll the anchor menu to the button
			$filter.closest('[data-overlayscrollbars-viewport]').scrollTo({
				left: $filter.offsetLeft - 8,
				behavior: 'smooth',
			});

			// update the anchor menu button immediately
			SetActiveState(filters, $filter);
			// wait to scroll until we can see the pretty animation
			scrollToTopOfFilter(() => {
				const category = getCategoryData($filter.dataset.category);
				setCurrentCategory(category, pageElements);
			});
		});
	});
}
