/**
 * Slide Up
 *
 * @param {string} target Selector
 * @param {number} duration number Time in ms
 */
export const slideUp = (target, duration = 500) => {
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = `${duration}ms`;
	target.style.boxSizing = 'border-box';
	target.style.height = `${target.offsetHeight}px`;
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	window.setTimeout(() => {
		target.style.display = 'none';
		target.style.removeProperty('height');
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
		// alert("!");
	}, duration);
};

/**
 * Slide Down
 *
 * @param {string} target Selector
 * @param {number} duration number Time in ms
 */
export const slideDown = (target, duration = 500) => {
	target.style.removeProperty('display');
	let { display } = window.getComputedStyle(target);

	if (display === 'none') display = 'block';

	target.style.display = display;
	const height = target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight;
	target.style.boxSizing = 'border-box';
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = `${duration}ms`;
	target.style.height = `${height}px`;
	target.style.removeProperty('padding-top');
	target.style.removeProperty('padding-bottom');
	target.style.removeProperty('margin-top');
	target.style.removeProperty('margin-bottom');
	window.setTimeout(() => {
		target.style.removeProperty('height');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
};

/**
 *
 * Slide Toggle
 *
 * @param {string} target Selector
 * @param {number} duration number Time in ms
 *
 * @return {Function} slide up or down
 */
export const slideToggle = (target, duration = 500) => {
	if (window.getComputedStyle(target).display === 'none') {
		return slideDown(target, duration);
	}
	return slideUp(target, duration);
};
