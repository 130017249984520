const $socialShareButton = document.querySelector('.social-share-button');

if ( $socialShareButton ) {
	const $socialShareTrigger = $socialShareButton.querySelector('.social-share-trigger');
	const shareLinks = $socialShareButton.querySelectorAll('ul a');

	$socialShareTrigger.addEventListener('click', () => {
		$socialShareButton.classList.toggle('active');
	});

	shareLinks.forEach(link => {
		const href = link.getAttribute('href');

		link.addEventListener('click', e => {
			e.preventDefault();
			window.open(
				href,
				'Social',
				'width=648,height=395,resizable=yes,scrollbars=yes,titlebar=yes'
			);
		});
	});
}

