import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import updateTotalHeight from '../../../scripts/components/updateTotalHeight';

gsap.registerPlugin(ScrollTrigger);

const $imageLogoSections = document.querySelectorAll(".section--image-logo");

const initImageLogoSections = () => {
	if ( $imageLogoSections ) {
		$imageLogoSections.forEach( $imageLogoSection => {

			const $logoContainer = $imageLogoSection.querySelector(".logo-container");

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: $logoContainer,
					start: `top 40%`,
					end: "bottom-=90% top+=20%",
					scrub: 1,
					// markers: true,
					onUpdate: (self) => {
						const progress = self.progress;
						gsap.to($logoContainer, {
							  opacity: progress,
						});
					},
				},
			});
		});
	}
};

window.addEventListener('load', () => {
	initImageLogoSections();
});

